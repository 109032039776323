import { createStore } from 'vuex'
const axios = require("axios").default;

const store = createStore({
  state: {
    product: {},
    loadingProduct: {},
    adminRedirectLink: null,
    robotRedirectLink: null,
    adminLogin: null,
    robotLogin: null,

    productsAdmin: [],
    categoriesAdmin: [],
    loadingProductAdmin: [],
    loadingProductAdminError: {},
    loadingCategoryAdmin: [],
    loadingCategoryAdminError: {},
    articlesAdmin: [],
    articleGroupAdmin: [],
    loadingArticleAdmin: [],
    loadingArticleAdminError: {},
    loadingArticleGroupAdmin: [],
    loadingArticleGroupAdminError: {},
    loadingKhoaHoc: false,
    products: null,
    khoaHoc: null,
    loadingBlog: false,
    blogs: null,
    navData: null,
    loadingNav: false,
    robotOnlineDevices: [],

    showCode: false,
    getCode: null,
    lastRemoteOutput: null,
    lastRemoteError: null,
    lastUserInput: null,
    modules: [],
    remoteOutput: {},
    remoteError: {},
    userInputValue: {},
    appReady: false,
    showLoadFile: false,
    showSaveFile: false,
    list_blockly_files: null,
    status_loading_blockly_list: false,
    file_data: {},
    workFileName: null,
    status_loading_file: {},
    last_time_run_code: 0,
  },
  getters: {
    product(state) {
      return function (link) {
        console.log(link);
        if (state.product[link]) {
          return state.product[link];
        } else {
          if (!state.loadingProduct[link]) {
            state.loadingProduct[link] = true;
            store.commit("loadProduct", link);
          }
          return null;
        }
      }
    },
    onlineDevices(state) {
      return state.robotOnlineDevices;
    },
    robotLoginData(state) {
      return state.robotLogin;
    },
    adminRedirectLink(state) {
      return state.adminRedirectLink
    },
    robotRedirectLink(state) {
      return state.robotRedirectLink
    },

    navData(state) {
      if (state.navData == null) {
        if (!state.loadingNav) {
          store.commit("loadNav");
        }
        return null;
      } else {
        return state.navData;
      }
    }, blogs(state) {
      if (state.blogs == null) {
        if (!state.loadingBlog) {
          store.commit("loadBlog");
        }
        return [];
      } else {
        return state.blogs;
      }
    },
    blog(state) {
      if (state.blogs == null) {
        if (!state.loadingBlog) {
          store.commit("loadBlog");
        }
        return function () {
          return null;
        };
      } else {
        return function (id) {
          return state.blogs.find(i => i._id == id);
        };
      }
    },
    khoaHoc(state) {
      if (state.khoaHoc == null) {
        if (!state.loadingKhoaHoc) {
          store.commit("loadKhoaHoc");
        }
        return [];
      } else {
        return state.khoaHoc;
      }
    },
    productAdmin(state) {
      return function (productId, lastUpdate) {
        if (state.loadingProductAdmin.indexOf(productId) != -1 ||
          (typeof state.loadingProductAdminError[productId] == 'number' && state.loadingProductAdminError[productId] + 5000 > Date.now())) {
          return null;
        } else {
          let product = state.productsAdmin.find(i => i._id.toLowerCase() == productId.toLowerCase());
          if (product) {
            if (typeof lastUpdate != "undefined") {
              if (product.lastUpdate == lastUpdate) {
                return product;
              } else {
                state.productsAdmin.splice(state.productsAdmin.indexOf(product), 1);
              }
            } else {
              return product;
            }
          }
          store.commit("loadProductAdmin", productId);
          return null;
        }
      }
    },
    categoryAdmin(state) {
      return function (categoryId, lastUpdate) {
        if (state.loadingCategoryAdmin.indexOf(categoryId) != -1 ||
          (typeof state.loadingCategoryAdminError[categoryId] == 'number' && state.loadingCategoryAdminError[categoryId] + 5000 > Date.now())) {
          return null;
        } else {
          let category = state.categoriesAdmin.find(i => i && i._id && i._id.toLowerCase() == categoryId.toLowerCase());
          if (category) {
            if (typeof lastUpdate != "undefined") {
              if (category.lastUpdate == lastUpdate) {
                return category;
              } else {
                state.categoriesAdmin.splice(state.categoriesAdmin.indexOf(category), 1);
              }
            } else {
              return category;
            }
          }
          store.commit("loadCategoryAdmin", categoryId);
          return null;
        }
      }
    },
    categoryAdmin2(state) {
      return function (categoryId) {
        categoryId = categoryId.trim();
        if (state.loadingCategoryAdmin.indexOf(categoryId) != -1 ||
          (typeof state.loadingCategoryAdminError[categoryId] == 'number' && state.loadingCategoryAdminError[categoryId] + 5000 > Date.now())) {
          return null;
        } else {
          let category = state.categoriesAdmin.find(i => i._id.toLowerCase() == categoryId.toLowerCase());
          if (category) {
            return state.categoriesAdmin.splice(state.categoriesAdmin.indexOf(category), 1);
          } else {
            store.commit("loadCategoryAdmin", categoryId);
          }
          return null;
        }
      }
    },

    articleAdmin(state) {
      return function (articleId, lastUpdate) {
        if (state.loadingArticleAdmin.indexOf(articleId) != -1 ||
          (typeof state.loadingArticleAdminError[articleId] == 'number' && state.loadingArticleAdminError[articleId] + 5000 > Date.now())) {
          return null;
        } else {
          let article = state.articlesAdmin.find(i => i._id == articleId);
          if (article) {
            if (typeof lastUpdate != "undefined") {
              if (article.lastUpdate == lastUpdate) {
                return article;
              } else {
                state.articlesAdmin.splice(state.articlesAdmin.indexOf(article), 1);
              }
            } else {
              return article;
            }
          }
          store.commit("loadArticleAdmin", articleId);
          return null;
        }
      }
    },
    articleGroupAdmin(state) {
      return function (categoryId, lastUpdate) {
        if (state.loadingArticleGroupAdmin.indexOf(categoryId) != -1 ||
          (typeof state.loadingArticleGroupAdminError[categoryId] == 'number' && state.loadingArticleGroupAdminError[categoryId] + 5000 > Date.now())) {
          return null;
        } else {
          let category = state.articleGroupAdmin.find(i => i._id == categoryId);
          if (category) {
            if (typeof lastUpdate != "undefined") {
              if (category.lastUpdate == lastUpdate) {
                return category;
              } else {
                state.articleGroupAdmin.splice(state.articleGroupAdmin.indexOf(category), 1);
              }
            } else {
              return category;
            }
          }
          store.commit("loadArticleGroupAdmin", categoryId);
          return null;
        }
      }
    },

    app_ready(state) {
      return state.appReady;
    },
    work_file_name(state) {
      return state.workFileName;
    },
    load_file(state) {
      if (state.workFileName) {
        if (state.file_data[state.workFileName]) {
          return state.file_data[state.workFileName];
        } else {
          if (!state.status_loading_file[state.workFileName]) {
            store.commit("load_file");
          }
          return null;
        }

      }
    },
    list_blockly_files(state) {
      if (state.list_blockly_files === null) {
        if (!state.status_loading_blockly_list) {
          store.commit("list_blockly_files");
        }
        return [];
      } else {
        return state.list_blockly_files;
      }
    },
    showLoadFile(state) {
      return state.showLoadFile;
    },
    showSaveFile(state) {
      return state.showSaveFile;
    },
    showCode(state) {
      return state.showCode;
    },
    lastUserInput(state) {
      return state.lastUserInput;
    },
    lastRemoteOutput(state) {
      return state.lastRemoteOutput;
    },
    lastRemoteError(state) {
      return state.lastRemoteError;
    },
    remoteOutput(state) {
      return state.remoteOutput;
    },
    remoteError(state) {
      return state.remoteError;
    },
    modules(state) {
      return state.modules;
    }
  },
  mutations: {

    async loadProduct(state, link) {
      if (link.substring(link.length - 1) == "/") {
        link = link.substring(0, link.length - 1);
      }
      try {
        let res = await axios.get("/api" + link + ".json");
        if (res.data) {
          state.loadingProduct[link] = false;
          state.product[link] = res.data;
        }
      } catch {
        console;
      }
    },
    signOut(state) {
      state.robotLogin = null;
    },
    commit_device_name() {
    },
    commit_add_device() {
    },
    commit_remove_device() {
    },
    update_device(state, data) {
      let check = state.robotOnlineDevices.find(i => i.device_unique_id == data.device_unique_id);
      if (check) {
        check.name = data.name;
        check.registered = data.registered;
      }
    },
    addOnlineDevice(state, device) {
      if (device && device.device_unique_id) {
        let check = state.robotOnlineDevices.find(i => i.device_unique_id == device.device_unique_id);
        if (!check) {
          console.log("add check");
          state.robotOnlineDevices.push(device);
        }
      }
    },
    removeOnlineDevice(state, device) {
      if (device && device.device_unique_id) {
        let check = state.robotOnlineDevices.find(i => i.device_unique_id == device.device_unique_id);
        if (check) {
          state.robotOnlineDevices.splice(state.robotOnlineDevices.indexOf(check), 1);
        }
      }
    },
    adminRedirectLink(state, link) {
      state.adminRedirectLink = link;
    },
    robotRedirectLink(state, link) {
      state.robotRedirectLink = link;
    },
    async loadKhoaHoc(state) {
      try {
        let res = await axios.get("/api/khoa-hoc");
        state.khoaHoc = res.data;
      } finally {
        state.loadingKhoaHoc = false;
      }
    },
    async loadNav(state) {

      try {
        let res = await axios.get("/api/nav");
        state.navData = res.data;
      } finally {
        state.loadingNav = false;
      }
    },
    async loadBlog(state) {
      try {
        let res = await axios.get("/api/blogs");
        state.blogs = res.data;
      } finally {
        state.loadingBlog = false;
      }
    },
    async loadProductAdmin(state, productId) {
      if (state.loadingProductAdmin.indexOf(productId) == -1) {
        state.loadingProductAdmin.push(productId);
        try {
          let res = await axios.get("/aapi/product/" + encodeURIComponent(productId));
          state.productsAdmin.push(res.data);
        } catch {
          state.loadingProductAdminError[productId] = Date.now();
        } finally {
          state.loadingProductAdmin.splice(state.loadingProductAdmin.indexOf(productId), 1);
        }
      }
    },
    async loadCategoryAdmin(state, categoryId) {

      if (categoryId != 0) {

        if (state.loadingCategoryAdmin.indexOf(categoryId) == -1) {
          state.loadingCategoryAdmin.push(categoryId);
          try {
            let res = await axios.get("/aapi/category/" + encodeURIComponent(categoryId));
            state.categoriesAdmin.push(res.data);
          } catch {
            state.loadingCategoryAdminError[categoryId] = Date.now();
          } finally {
            state.loadingCategoryAdmin.splice(state.loadingCategoryAdmin.indexOf(categoryId), 1);
          }
        }
      }
    },
    login(state, data) {
      state.adminLogin = data;
    },
    robotLogin(state, data) {
      state.robotLogin = data;
    },

    async loadArticleAdmin(state, articleId) {
      if (state.loadingArticleAdmin.indexOf(articleId) == -1) {
        state.loadingArticleAdmin.push(articleId);
        try {
          let res = await axios.get("/aapi/article/" + encodeURIComponent(articleId));
          state.articlesAdmin.push(res.data);
        } catch {
          state.loadingArticleAdminError[articleId] = Date.now();
        } finally {
          state.loadingArticleAdmin.splice(state.loadingArticleAdmin.indexOf(articleId), 1);
        }
      }
    },
    async loadArticleGroupAdmin(state, articleGroupId) {
      if (articleGroupId != 0) {
        if (state.loadingArticleGroupAdmin.indexOf(articleGroupId) == -1) {
          state.loadingArticleGroupAdmin.push(articleGroupId);
          try {
            let res = await axios.get("/aapi/article-group/" + encodeURIComponent(articleGroupId));
            state.articleGroupAdmin.push(res.data);
          } catch {
            state.loadingArticleGroupAdminError[articleGroupId] = Date.now();
          } finally {
            state.loadingArticleGroupAdmin.splice(state.loadingArticleGroupAdmin.indexOf(articleGroupId), 1);
          }
        }

      }
    },

    app_ready(state, status) {
      state.appReady = status;
    },
    save_blockly_file() {
    },
    //request blockly files
    list_blockly_files() {
    },
    //server response blockly files
    blockly_files(state, save_files) {
      if (state.status_loading_blockly_list) {
        state.status_loading_blockly_list = false;
      }
      state.list_blockly_files = save_files;
    },
    workFileName() {
    },
    file_content(state, data) {
      if (data["file_name"] && data["content"]) {
        let file_name = data["file_name"];
        let content = data["content"];
        if (state.status_loading_file[file_name]) {
          state.status_loading_file[file_name] = false;
          state.file_data[file_name] = content;
        }
      }
    },
    load_file(state) {
      if (state.workFileName) {
        if (!state.status_loading_file[state.workFileName]) {
          state.status_loading_file[state.workFileName] = true;
        }
      }
    },
    toggleLoadFile(state) {
      state.showLoadFile = !state.showLoadFile;
    },
    newFile(state) {
      state.workFileName = null;
      window.localStorage.setItem("workFileName", "");
    },
    toggleSaveFile(state) {
      state.showSaveFile = !state.showSaveFile;
    },
    modulesList(state, modules) {
      state.modules = modules;
    },
    toggleCode(state) {
      state.showCode = !state.showCode;
    },
    setCallbackSetCode(state, cb) {
      state.getCode = cb;
    },
    playCode(state) {
      let now = Date.now();
      if (now - state.last_time_run_code > 1000 && state.getCode) {
        state.last_time_run_code = now;
        let code = state.getCode();
        if (typeof code != "undefined") {
          store.commit("app_ready", false);
        }
      }
    },
    stopCode() {
    },
    remoteOutput(state, output) {
      state.lastRemoteOutput = output;
      if (output[0]) {
        state.remoteOutput[output[0]] = output.slice(1);
      }
    },
    userInput(state, input) {
      state.lastUserInput = input;
    },
    remoteError(state, output) {
      state.lastRemoteError = output;
      if (output[0]) {
        state.remoteError[output[0]] = output.slice(1);
      }
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;
